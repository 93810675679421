
import { Watch, Emit, Component, Vue, Ref, Prop, PropSync, InjectReactive } from "vue-property-decorator";
import { saveOrUpdatePresCategory } from "@/api/prescriptionDb";
import { cloneDeep } from "lodash";

import { deviceClassList } from "@/api/deviceType";

interface IFormData {
    id: number,
    parentId: number,
    name: string,
    sort: number,
    categoryType: number,
    productRemark: string,
    status: number,
    deviceClassId?: number|string,
    categoryTypeName?: string,
}

@Component
export default class AddProductType extends Vue{
    @Ref("dialogForm") readonly dialogForm!: VForm;
    @PropSync("formData", { type: Object, default: () => ({}) }) form!: IFormData;
    // @InjectReactive() readonly deviceTypeOptions!: Array<any>;
    @InjectReactive() merchantId!: string|number;
    @Prop() readonly productInfo: any;

    deviceTypeOptions = [] as Array<any> ;
    productTypeOptions = [{ label: "项目产品库", value: 1 }, { label: "居家产品库", value: 2 }, { label: "其他库", value: 3 }]

    dialogFormVisible = false;

    formRules = {
      parentId: [{ required: true, message: "请选择产品分类" }],
      name: [{ required: true, message: "请输入产品分类名称" }],
      deviceClassId: [{ required: true, message: "请选择关联设备" }],
    }

    saveLoading = false;

    mounted() {
      console.log(this.merchantId);
      this.formRules.deviceClassId[0].required = this.merchantId ? (parseInt(this.merchantId.toString()) == 0) : true;
      this.getHealthType();
    }

    getHealthType() {
      deviceClassList({}).then((res) => {
        this.deviceTypeOptions = res.data;
      })
    }

    get deviceTypeTlOptions() {
      return this.deviceTypeOptions?.find(el => el.id == 3)
    }

    get dialogTitle() {
      return this.form?.id ? "编辑产品分类" : "新增产品分类";
    }

    parentIdChange() {
      this.form.deviceClassId = undefined;
    }

    dialogConfirm() {
      this.dialogForm?.validate().then((res) => {
        this.saveLoading = true;
        const params = cloneDeep(this.form)
        delete params.categoryTypeName;
        // majorName 默认与 name 一致
        params.status = Number(params.status);
        params.majorName = params.name;
        Object.assign(params, { categoryType: 1, merchantId: this.merchantId, ownerType: this.merchantId ? 1: 0 })
        saveOrUpdatePresCategory(params).then((res) => {
          this.dialogClose();
          this.form.name = "";
          this.refreshProductList();
        }).finally(() => {
          this.saveLoading = false
        })
      }).catch((err) => {
        console.log(err)
      })
    }
    dialogClose() {
        this.dialogFormVisible = false;
        this.dialogForm?.resetFields();
    }
    @Emit("refreshProductList")
    refreshProductList() {
        return true;
    }
}


